<template>
  <HeaderNav>
    <a
      @click="$router.go(-1)"
      class="p-2 inline-block text-center transition duration-300"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-arrow-left"
      >
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
      </svg>
    </a>
  </HeaderNav>
  <div class="max-w-md rounded-b-lg bg-coklat-2 p-4 mx-auto">
    <div class="w-full mt-5 mb-3 font-NotoSans text-xl font-bold">
      Tambah Ruh
    </div>
    <form v-on:submit.prevent="addMayat">
      <div class="mb-4 flex gap-4">
        <div class="">
          <input
            class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Nama Ruh"
            v-model="newNameMayat"
            required
          />
        </div>
        <div class="">
          <select
            id="countries"
            class="px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            required
            v-model="newJkMayat"
          >
            <option value="">Bin/Binti</option>
            <option value="bin" selected>Bin</option>
            <option value="binti">Binti</option>
          </select>
        </div>
        <div class="">
          <input
            class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="lastName"
            type="text"
            placeholder="Orangtua ruh"
            v-model="newOrtuMayat"
            required
          />
        </div>
      </div>
      <button
        class="inline-flex items-center px-4 py-2 bg-white hover:scale-75 text-blue-900 text-sm font-medium rounded-md"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-send mr-1 text-blue-900"
        >
          <line x1="22" y1="2" x2="11" y2="13"></line>
          <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
        </svg>
        Simpan
      </button>
    </form>
  </div>
  <div class="max-w-md mx-4 md:mx-auto" style="margin-bottom: 68px">
    <router-link to="/aplikasi/tahlil">
      <div
        class="bg-gradient-to-r from-coklat-1 via-coklat-3 to-coklat-1 py-4 text-black rounded-lg text-center text-2xl font-Amiri my-5"
      >
        تَهْلِيْل
      </div>
    </router-link>
    <section class="bg-white pb-10 lg:py-[120px]">
      <div class="container">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full px-4">
            <div class="max-w-full overflow-x-auto">
              <table
                class="min-w-full text-sm border border-gray-100 divide-y-2 divide-gray-200"
              >
                <thead>
                  <tr class="divide-x divide-gray-100">
                    <th
                      class="px-4 py-2 font-medium text-center text-gray-900 whitespace-nowrap"
                    >
                      Nama ruh
                    </th>
                    <th
                      class="px-4 py-2 font-medium text-center text-gray-900 whitespace-nowrap"
                    >
                      Bin/Binti
                    </th>
                    <th
                      class="px-4 py-2 font-medium text-center text-gray-900 whitespace-nowrap"
                    >
                      Ortu Ruh
                    </th>
                    <th
                      class="px-4 py-2 font-medium text-center text-gray-900 whitespace-nowrap"
                    >
                      Aksi
                    </th>
                  </tr>
                </thead>

                <tbody class="divide-y divide-gray-200">
                  <tr
                    v-for="(mayat, i) in mayats"
                    :key="i"
                    class="divide-x divide-gray-100 text-center"
                  >
                    <td class="px-4 py-2 text-gray-700 whitespace-nowrap">
                      {{ mayat.nama }}
                    </td>
                    <td class="px-4 py-2 text-gray-700 whitespace-nowrap">
                      {{ mayat.jk }}
                    </td>
                    <td class="px-4 py-2 text-gray-700 whitespace-nowrap">
                      {{ mayat.ortu }}
                    </td>
                    <td class="px-4 py-2 text-gray-700 whitespace-nowrap">
                      <button
                        class="inline-flex items-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded-md"
                        @click="removeMayat(i)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 mr-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>

                        Delete
                      </button>
                    </td>
                  </tr>
                  <tr v-if="!mayats.length">
                    <td colspan="4" class="text-center pt-3">
                      Tidak ada Data Ruh
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    HeaderNav,
  },
  data() {
    return {
      mayats: [],
      newNameMayat: "",
      newJkMayat: "",
      newOrtuMayat: "",
    };
  },
  mounted() {
    if (localStorage.getItem("mayats")) {
      try {
        this.mayats = JSON.parse(localStorage.getItem("mayats"));
      } catch (e) {
        localStorage.removeItem("mayats");
      }
    }
  },
  methods: {
    addMayat() {
      this.mayats.push({
        nama: this.newNameMayat,
        jk: this.newJkMayat,
        ortu: this.newOrtuMayat,
      });

      this.newNameMayat = "";
      this.newJkMayat = "";
      this.newOrtuMayat = "";
      this.saveMayat();
    },
    removeMayat(x) {
      this.mayats.splice(x, 1);
      this.saveMayat();
    },
    saveMayat() {
      const parsed = JSON.stringify(this.mayats);
      localStorage.setItem("mayats", parsed);
    },
  },
};
</script>
