<template>
  <HeaderNav>
    <a
      @click="$router.go(-1)"
      class="p-2 inline-block text-center transition duration-300"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-arrow-left"
      >
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
      </svg>
    </a>
  </HeaderNav>
  <div class="max-w-md mx-auto mt-4" style="margin-bottom: 68px">
    <div class="lg:mx-auto mb-2">
      <div class="mx-auto text-center">
        <div class="font-NotoSerif" id="pengaturan">
          <p class="font-bold">{{ isiSurat.surat.name }}</p>
          <p>
            {{ isiSurat.surat.translation }}
          </p>
        </div>
      </div>
      <div class="opacity-100">
        <div
          class="bg-coklat-2 text-white rounded-b-lg p-5 grid md:grid-cols-3 grid-cols-2 items-center w-full mt-4 gap-4"
        >
          <div class="flex gap-2 items-center">
            <p class="font-NotoSans text-sm">Tafsir</p>
            <label class="switch">
              <input
                type="checkbox"
                @click="showTafsir"
                :checked="tafsir == true"
              />
              <div class="slider round"></div>
            </label>
          </div>
          <div class="flex gap-2 items-center">
            <p class="font-NotoSans text-sm">Terjemah</p>
            <label class="switch">
              <input
                type="checkbox"
                @click="showTranslate"
                :checked="translate == true"
              />
              <div class="slider round"></div>
            </label>
          </div>
          <div class="flex gap-2 items-center">
            <p class="font-NotoSans text-sm">Muqaddimah</p>
            <label class="switch">
              <input
                type="checkbox"
                @click="showPembuka"
                :checked="showDescription == true"
              />
              <div class="slider round"></div>
            </label>
          </div>
        </div>
      </div>
      <div
        v-if="isiSurat.surat.number !== 9"
        class="font-LPMQ arab text-center my-3"
      >
        {{ isiSurat.basmalah.arab }}
      </div>
      <div
        class="flex items-center hover:shadow-xl shadow-md rounded-lg gap-2 mb-4 mx-2 p-3"
        v-if="showDescription"
      >
        <p>{{ isiSurat.surat.description }}</p>
      </div>
      <div v-for="(isi, key) in isiSurat.surat.ayahs" :key="key">
        <div
          class="items-start flex hover:shadow-xl shadow-md rounded-lg mb-4 mx-2"
        >
          <div class="p-2 sticky relative text-center" style="top: 55px">
            <img
              :src="require(`@/assets/ayat.png`)"
              style="max-width: 38px"
              class="w-full"
            />
            <p
              class="absolute w-full text-center"
              style="max-width: 38px; top: 19px; right: 6px"
            >
              {{ isi.number.inSurah }}
            </p>
            <!-- {{ isi.number.inSurah }} -->
          </div>
          <div class="mb-4 pt-5 pr-2 w-full">
            <div class="font-LPMQ arab w-full">
              {{ isi.arab }}
            </div>
            <div
              v-if="translate"
              class="font-NotoSans mt-2"
              v-html="isi.translation"
            ></div>
            <div v-if="tafsir">
              <p class="font-bold mt-2">Tafsir Kemenag Ringkas</p>
              <div
                class="font-NotoSans mt-2"
                v-html="isi.tafsir.kemenag.short"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <BottomNav>
    <router-link
      to="/"
      class="w-full justify-center inline-block text-center pt-2 pb-1 transition duration-300 hover:text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="h-6 w-6 inline-block mb-1"
      >
        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
        <polyline points="9 22 9 12 15 12 15 22"></polyline>
      </svg>
      <span class="tab block text-xs">Beranda</span>
    </router-link>
    <a
      @click="goToSettings"
      class="w-full justify-center inline-block text-center pt-2 pb-1 transition duration-300 hover:text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="h-6 w-6 inline-block mb-1"
      >
        <circle cx="12" cy="12" r="3"></circle>
        <path
          d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
        ></path>
      </svg>
      <span class="tab block text-xs">Pengaturan</span>
    </a>
    <a
      @click="scrollToTop"
      class="w-full justify-center inline-block text-center pt-2 pb-1 transition duration-300 hover:text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="h-6 w-6 inline-block mb-1"
      >
        <polyline points="10 9 15 4 20 9"></polyline>
        <path d="M4 20h7a4 4 0 0 0 4-4V4"></path>
      </svg>
      <span class="tab block text-xs">Ke Atas</span>
    </a>
  </BottomNav>
</template>
<script>
import { onMounted, ref, reactive } from "vue";
import axios from "@/plugins/axios";
import HeaderNav from "@/components/HeaderNav.vue";
import BottomNav from "@/components/BottomNav.vue";
export default {
  name: "DetailTahlil",
  components: {
    BottomNav,
    HeaderNav,
  },
  data() {
    let showDescription = localStorage.getItem("showDescription") == "true";
    let translate = localStorage.getItem("translate") == "true";
    let tafsir = localStorage.getItem("tafsir") == "true";

    return {
      showDescription,
      translate,
      tafsir,
    };
  },
  props: ["surah"],

  setup(props) {
    const no = ref(props.surah);
    const isiSurat = reactive({
      surat: {},
      basmalah: {},
    });
    onMounted(async () => {
      const { data } = await axios.get(
        "https://quran-api-id.vercel.app/surahs/" + no.value
      );
      isiSurat.surat = data;
      isiSurat.basmalah = data.bismillah;
    });
    return {
      no,
      isiSurat,
    };
  },
  methods: {
    scrollToTop() {
      const el = document.getElementById("app");
      el.scrollIntoView({ behavior: "smooth" });
    },
    goToSettings() {
      const el = document.getElementById("pengaturan");
      el.scrollIntoView({ behavior: "smooth" });
    },
    showTafsir() {
      this.tafsir = !this.tafsir;
      localStorage.setItem("tafsir", this.tafsir);
    },
    showTranslate() {
      this.translate = !this.translate;
      localStorage.setItem("translate", this.translate);
    },
    showPembuka() {
      this.showDescription = !this.showDescription;
      localStorage.setItem("showDescription", this.showDescription);
    },
  },
};
</script>
<style lang="css" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 21px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d1c3b7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: #836853;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider::after {
}
input:checked + .slider {
  background-color: #836853;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  background-color: #d1c3b7;
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
