<template>
  <HeaderNav>
    <a
      @click="$router.go(-1)"
      class="p-2 inline-block text-center transition duration-300"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-arrow-left"
      >
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
      </svg>
    </a>
  </HeaderNav>

  <div class="opacity-100 max-w-md mx-auto" style="z-index: 999">
    <div
      class="bg-coklat-2 text-white rounded-b-lg p-5 grid md:grid-cols-3 grid-cols-2 items-center w-full gap-4"
    >
      <div class="flex gap-2 items-center">
        <p class="font-NotoSans text-sm">Surat Yasin</p>
        <label class="switch">
          <input type="checkbox" @click="showYasinan" :checked="yasinActive" />
          <div class="slider round"></div>
        </label>
      </div>
      <div class="flex gap-2 items-center">
        <p class="font-NotoSans text-sm">Latin</p>
        <label class="switch">
          <input type="checkbox" @click="showLatin" :checked="latin" />
          <div class="slider round"></div>
        </label>
      </div>
    </div>
  </div>
  <div class="max-w-md mx-4 md:mx-auto" style="margin-bottom: 68px">
    <div v-for="(isi, key) in allTahlil.tahlil" :key="key">
      <div class="shadow-md w-1/4 mx-auto rounded-lg">
        <p class="font-LPMQ arab p-4" :id="isi.jenis">{{ isi.jenis }}</p>
      </div>
      <div v-for="(tahlil, key) in isi.konten" :key="key">
        <div
          class="flex w-full items-center gap-4 shadow-md rounded-lg hover:shadow-lg mb-3"
        >
          <div class="mb-4 p-5 w-full">
            <div class="font-LPMQ arab w-full">
              <p v-if="tahlil.id === 3 && !mayats.length">
                {{
                  tahlil.arab +
                  " . . . بِنْ/بِنْتِ . . . شَيْئٌ لِلّٰهِ لَهُمْ الْفَاتِحَة . . . "
                }}
              </p>
              <p v-else>
                {{ tahlil.arab }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="tahlil.id === 3 && mayats.length"
          class="flex w-full items-center gap-4 shadow-md rounded-lg hover:shadow-lg items-center mb-3"
        >
          <table class="min-w-full text-sm divide-y divide-gray-200">
            <tbody class="divide-y divide-gray-100">
              <tr
                v-for="(mayat, index) in mayats"
                :key="index"
                class="text-center"
              >
                <td
                  class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap"
                >
                  {{ mayat.nama }}
                </td>
                <td class="px-4 py-2 text-gray-700 whitespace-nowrap">
                  {{ mayat.jk }}
                </td>
                <td class="px-4 py-2 text-gray-700 whitespace-nowrap">
                  {{ mayat.ortu }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="tahlil.id === 3 && mayats.length"
          class="flex w-full items-center gap-4 shadow-md rounded-lg hover:shadow-lg items-center mb-3 p-5"
        >
          <div class="font-LPMQ arab w-full">
            {{ " شَيْئٌ لِلّٰهِ لَهُْمْ الْفَاتِحَة . . . " }}
          </div>
        </div>
        <div v-if="tahlil.id === 3 && yasinActive">
          <div class="mb-4 p-5 w-full">
            <div
              class="font-LPMQ arab w-full text-shadow font-bold text-coklat-1"
            >
              {{ allTahlil.suratYasin.bismillah.arab }}
            </div>
          </div>
          <div
            v-for="(Qs, idYasin) in allTahlil.suratYasin.ayahs"
            :key="idYasin"
          >
            <div
              class="flex w-full items-start gap-4 shadow-md rounded-lg hover:shadow-lg mb-3"
            >
              <div class="p-2 sticky relative text-center" style="top: 55px">
                <img
                  :src="require(`@/assets/ayat.png`)"
                  style="max-width: 38px"
                  class="w-full"
                />
                <p
                  class="absolute w-full text-center"
                  style="max-width: 38px; top: 19px; right: 6px"
                >
                  {{ Qs.number.inSurah }}
                </p>
                <!-- {{ isi.number.inSurah }} -->
              </div>
              <div class="mb-4 pt-5 pr-2 w-full">
                <div class="font-LPMQ arab w-full">
                  {{ Qs.arab }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="latin"
    class="font-sans antialiased fixed inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center ease-in duration-300"
  >
    <div class="fixed inset-0 transition-opacity">
      <div
        class="absolute inset-0 bg-coklat-3 backdrop-blur-lg max-w-md mx-auto opacity-75"
      ></div>
    </div>
    <div
      class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all duration-300 max-w-sm mx-4"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-red-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Informasi
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                <strong>Abdilah </strong>memohon maaf, karena fitur latin belum
                dapat diselesaikan.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            @click="showLatin"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-coklat-1 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Tutup
          </button>
        </span>
      </div>
    </div>
  </div>

  <BottomNav>
    <router-link
      to="/aplikasi/tahlil/addruh"
      class="w-full justify-center inline-block text-center pt-2 pb-1 transition duration-300 hover:text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="h-6 w-6 inline-block mb-1"
      >
        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="8.5" cy="7" r="4"></circle>
        <line x1="20" y1="8" x2="20" y2="14"></line>
        <line x1="23" y1="11" x2="17" y2="11"></line>
      </svg>
      <span class="tab block text-xs">Tambah Ruh</span>
    </router-link>
    <a
      @click="goToSettings"
      class="w-full justify-center inline-block text-center pt-2 pb-1 transition duration-300 hover:text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="h-6 w-6 inline-block mb-1"
      >
        <circle cx="12" cy="12" r="3"></circle>
        <path
          d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
        ></path>
      </svg>
      <span class="tab block text-xs">Pengaturan</span>
    </a>
    <a
      @click="goToPray"
      class="w-full justify-center inline-block text-center pt-2 pb-1 transition duration-300 hover:text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="h-6 w-6 inline-block mb-1"
      >
        <polyline points="14 15 9 20 4 15"></polyline>
        <path d="M20 4h-7a4 4 0 0 0-4 4v12"></path>
      </svg>
      <span class="tab block text-xs">Doa</span>
    </a>
    <a
      @click="scrollToTop"
      class="w-full justify-center inline-block text-center pt-2 pb-1 transition duration-300 hover:text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="h-6 w-6 inline-block mb-1"
      >
        <polyline points="10 9 15 4 20 9"></polyline>
        <path d="M4 20h7a4 4 0 0 0 4-4V4"></path>
      </svg>
      <span class="tab block text-xs">Ke Atas</span>
    </a>
  </BottomNav>
</template>

<script>
import { onMounted, reactive } from "vue";
import axios from "@/plugins/axios";
import HeaderNav from "@/components/HeaderNav.vue";
import BottomNav from "@/components/BottomNav.vue";
export default {
  components: {
    HeaderNav,
    BottomNav,
  },
  data() {
    let yasinActive = localStorage.getItem("yasinan") == "true";
    let latin = false;
    return {
      yasinActive,
      latin,
      mayats: [],
    };
  },
  methods: {
    goToSettings() {
      const el = document.getElementById("app");
      el.scrollIntoView({ behavior: "smooth" });
    },
    scrollToTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    goToPray() {
      const el = document.getElementById("دُعَاء");
      el.scrollIntoView({ behavior: "smooth" });
    },
    showYasinan() {
      this.yasinActive = !this.yasinActive;
      localStorage.setItem("yasinan", this.yasinActive);
    },
    showLatin() {
      this.latin = !this.latin;
    },
  },
  setup() {
    const allTahlil = reactive({
      tahlil: [],
      suratYasin: {},
    });
    onMounted(async () => {
      const { data } = await axios.get(
        "https://raw.githubusercontent.com/uminabdillah/WebAplikasiAbdilah/main/tahlil.json"
      );
      allTahlil.tahlil = data;

      axios
        .get("https://quran-api-id.vercel.app/surahs/36")
        .then((response) => (allTahlil.suratYasin = response.data));
    });
    return {
      allTahlil,
    };
  },
  mounted() {
    if (localStorage.getItem("mayats")) {
      try {
        this.mayats = JSON.parse(localStorage.getItem("mayats"));
      } catch (e) {
        // localStorage.removeItem("mayats");
      }
    }
  },
};
</script>

<style lang="css" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 21px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d1c3b7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: #836853;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider::after {
}
input:checked + .slider {
  background-color: #836853;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  background-color: #d1c3b7;
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
.text-shadow {
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
</style>
