import { createRouter, createWebHistory } from "vue-router";
import BerandaView from "../views/BerandaView.vue";

import QuranView from "../views/QuranView.vue";
import SurahQuran from "../views/quran/DetailView.vue";
import Tahlil from "../views/tahlil/DetailTahlil.vue";
import AddRuh from "../views/tahlil/AddRuh.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: BerandaView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/aplikasi/quran",
    name: "aplikasiQuran",
    component: QuranView,
  },
  {
    path: "/aplikasi/quran/:surah",
    name: "surahQuran",
    component: SurahQuran,
    props: true,
  },
  {
    path: "/aplikasi/tahlil",
    name: "aplikasiiTahlil",
    component: Tahlil,
  },
  {
    path: "/aplikasi/tahlil/addRuh",
    name: "addRuh",
    component: AddRuh,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
