<template>
  <div class="max-w-md mx-auto mb-5">
    <section
      class="bg-coklat-2 dark:bg-gray-600 shadow-xl"
      style="border-radius: 0 0 20px 20px"
    >
      <div class="px-4 pt-5 mx-auto max-w-screen-xl lg:items-center lg:flex">
        <div class="max-w-xl mx-auto text-center">
          <p class="mt-4 sm:leading-relaxed sm:text-xl font-LPMQ text-white">
            خَيْرُ النَّاسِ أَنْفَعُهُمْ لِلنَّاسِ
          </p>
          <p class="font-NotoSerif mt-1">
            sebaik-baik manusia adalah orang yang paling bermanfaat bagi
            manusia.
          </p>
          <p class="text-5xl font-NotoSans">"</p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {};
</script>
<style lang=""></style>
