<template>
  <div>
    <router-view />
  </div>
</template>
<script></script>
<style>
.arab {
  font-size: 26px;
  line-height: 50px;
  font-weight: normal;
  direction: rtl;
}
</style>
