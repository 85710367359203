<template>
  <div class="sticky top-0 z-50 mx-auto">
    <section class="py-2 shadow-lg text-gray bg-coklat-3 max-w-md">
      <div id="tabs" class="flex justify-between">
        <slot> </slot>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HeaderNav",
  data() {
    let isDark = localStorage.getItem("darkMode") == "true";
    return {
      isDark,
    };
  },
  methods: {
    toggleDark() {
      this.isDark = !this.isDark;
      localStorage.setItem("darkMode", this.isDark);
    },
  },
};
</script>
<style lang=""></style>
