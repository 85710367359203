<template>
  <section
    class="block fixed bottom-0 inset-x-0 z-50 shadow-lg text-gray bg-coklat-3 dark:text-gray-400 max-w-md mx-auto rounded-t-lg"
  >
    <div id="tabs" class="flex justify-between">
      <slot> </slot>
      <!-- <router-link
        to="/"
        class="w-full justify-center inline-block text-center pt-2 pb-1 transition duration-300 hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 inline-block mb-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
        <span class="tab block text-xs">Home</span>
      </router-link> -->
    </div>
  </section>
  <router-view></router-view>
</template>
<script>
export default {
  name: "BottomNav",
};
</script>
<style lang=""></style>
