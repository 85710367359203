<template>
  <HeaderNav>
    <a
      @click="$router.go(-1)"
      class="p-2 inline-block text-center transition duration-300"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-arrow-left"
      >
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
      </svg>
    </a>
  </HeaderNav>
  <div class="max-w-md mx-4 md:mx-auto mt-4 text-center">
    <div class="grid grid-rows-2 mx-auto grid-cols-1 gap-2">
      <div v-for="(surah, key) in allSurah.quran" :key="key">
        <router-link
          :to="{ name: 'surahQuran', params: { surah: surah.number } }"
          class="flex border border-2 bg-white font-NotoSans hover:shadow-lg py-4 rounded-lg shadow-sm"
        >
          <div class="flex flex-1 items-center justify-between ml-4">
            <div class="border-r-2 pr-2 py-2">
              {{ surah.number }}
            </div>
            <div class="flex flex-1 items-center justify-between ml-4">
              <div class="flex flex-col text-left">
                <div class="text-md md:text-lg">
                  {{ surah.name }} ({{ surah.numberOfAyahs }})
                </div>
                <div class="text-sm">{{ surah.translation }}</div>
              </div>
            </div>
            <div class="font-Amiri arab text-center justify-end mr-4">
              <svg
                height="32px"
                style="
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  stroke-linejoin: round;
                  stroke-miterlimit: 2;
                "
                version="1.1"
                width="32px"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:serif="http://www.serif.com/"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M7.702,19.094l-2.918,1.459c-0.169,0.084 -0.276,0.257 -0.276,0.446c0,0 -0.004,1.813 -0.006,2.998c-0.001,0.398 0.157,0.781 0.438,1.063c0.282,0.282 0.664,0.44 1.062,0.44c1.185,0 2.998,0 2.998,0c0.276,0 0.5,-0.224 0.5,-0.5l0,-1.64l5.581,-1.86l1.839,0c0,0 5.58,1.86 5.58,1.86c0,0 0,1.64 0,1.64c0,0.276 0.224,0.5 0.5,0.5l3,0c0.828,0 1.5,-0.672 1.5,-1.5l-0,-3c-0,-0.189 -0.107,-0.362 -0.276,-0.447l-2.921,-1.46l4.171,-1.391c0.613,-0.204 1.026,-0.777 1.026,-1.423l0,-2.279c0,-0.161 -0.077,-0.312 -0.208,-0.406c-0.13,-0.094 -0.298,-0.119 -0.45,-0.068c-3.974,1.324 -7.982,2.806 -11.915,3.974l-1.846,0c0,0 -11.923,-3.974 -11.923,-3.974c-0.152,-0.051 -0.32,-0.026 -0.45,0.068c-0.131,0.094 -0.208,0.245 -0.208,0.406l0,2.279c0,0.646 0.413,1.219 1.026,1.423l4.176,1.392Z"
                  style="fill: #8ac4d0"
                />
                <path
                  d="M7.002,25.5c-0.398,0 -0.78,-0.158 -1.062,-0.44c-0.281,-0.282 -0.439,-0.665 -0.438,-1.063c0.002,-1.185 0.006,-2.998 0.006,-2.998c0,-0.189 0.107,-0.362 0.276,-0.446l2.918,-1.459l-4.176,-1.392c-0.613,-0.204 -1.026,-0.777 -1.026,-1.423l-0,-2.279c-0,-0.119 0.042,-0.232 0.117,-0.321l-0.459,-0.153c-0.152,-0.051 -0.32,-0.026 -0.45,0.068c-0.131,0.094 -0.208,0.245 -0.208,0.406l0,2.279c0,0.646 0.413,1.219 1.026,1.423l4.176,1.392l-2.918,1.459c-0.169,0.084 -0.276,0.257 -0.276,0.446c0,0 -0.004,1.813 -0.006,2.998c-0.001,0.398 0.157,0.781 0.438,1.063c0.282,0.282 0.664,0.44 1.062,0.44l1,0Zm9.079,-8l1.846,0c3.782,-1.123 7.632,-2.537 11.456,-3.821c-0.026,-0.031 -0.056,-0.06 -0.091,-0.085c-0.13,-0.094 -0.298,-0.119 -0.45,-0.068c-3.974,1.324 -7.982,2.806 -11.915,3.974l-0.846,0Z"
                  style="fill: #a4cfd8"
                />
                <path
                  d="M27.153,14.976c0.201,-0.065 0.347,-0.253 0.347,-0.476l0,-1.072c-0,-0.489 -0.239,-0.948 -0.639,-1.228c-0.401,-0.281 -0.914,-0.349 -1.374,-0.181l-9.487,3.449l-9.486,-3.45c-0.46,-0.167 -0.973,-0.099 -1.374,0.182c-0.401,0.281 -0.639,0.74 -0.639,1.229c0.001,0.561 0.001,1.074 0.001,1.074c0,0.212 0.134,0.401 0.335,0.471l9.998,3.498c0.053,0.019 0.109,0.028 0.165,0.028l2,0c0.056,0 0.112,-0.009 0.165,-0.028l10,-3.498l-0.012,0.002Z"
                  style="fill: #28527a"
                />
                <path
                  d="M4.837,14.974c-0,0 10.815,4.027 1.163,0.407c-0.322,-0.121 -0.499,-0.666 -0.499,-0.878c-0,-0 -0.001,-0.513 -0.001,-1.074c-0.001,-0.489 0.238,-0.948 0.639,-1.229c0.113,-0.079 0.234,-0.141 0.361,-0.186c-0.456,-0.161 -0.963,-0.092 -1.36,0.186c-0.401,0.281 -0.639,0.74 -0.639,1.229c0.001,0.561 0.001,1.074 0.001,1.074c0,0.212 0.134,0.401 0.335,0.471Z"
                  style="fill: #507496"
                />
                <path
                  d="M14.5,22c-0,0.276 0.224,0.5 0.5,0.5l2,0c0.276,0 0.5,-0.224 0.5,-0.5l-0,-4c0,-0.276 -0.224,-0.5 -0.5,-0.5l-2,0c-0.276,0 -0.5,0.224 -0.5,0.5l-0,4Z"
                  style="fill: #f4d160"
                />
                <path
                  d="M16,17.5l-1,0c-0.276,0 -0.5,0.224 -0.5,0.5l-0,4c-0,0.276 0.224,0.5 0.5,0.5l1,0c-0.276,0 -0.5,-0.224 -0.5,-0.5l-0,-4c-0,-0.276 0.224,-0.5 0.5,-0.5Z"
                  style="fill: #dcc26f"
                />
                <path
                  d="M26.186,12.464c0.246,-0.098 0.372,-0.371 0.288,-0.622c0,-0 -0.42,-1.261 -0.716,-2.148c-0.247,-0.743 -1.024,-1.171 -1.784,-0.982c-0.002,0 -0.003,0.001 -0.004,0.001c-1.538,0.394 -4.597,1.334 -6.31,2.92c-0.833,0.769 -1.345,1.443 -1.66,1.981c-0.314,-0.538 -0.826,-1.212 -1.66,-1.981c-1.714,-1.586 -4.774,-2.527 -6.315,-2.913c0,0 -0,0 -0.001,0c-0.756,-0.188 -1.529,0.238 -1.775,0.976c-0.302,0.883 -0.723,2.146 -0.723,2.146c-0.084,0.251 0.043,0.524 0.288,0.622l10.001,4c0.12,0.048 0.253,0.048 0.372,0l9.999,-4Z"
                  style="fill: #fbeeac"
                />
                <path
                  d="M5.814,12.464l0.686,0.275c-0.246,-0.099 -0.058,-0.646 0.026,-0.897c-0,-0 0.421,-1.263 0.723,-2.146c0.151,-0.454 0.503,-0.79 0.926,-0.938c-0.051,-0.013 -0.101,-0.026 -0.15,-0.038c0,0 -0,0 -0.001,0c-0.756,-0.188 -1.529,0.238 -1.775,0.976c-0.302,0.883 -0.723,2.146 -0.723,2.146c-0.084,0.251 0.043,0.524 0.288,0.622Zm10.187,1.149c1.011,-0.854 1.651,-1.397 2.659,-1.98c2.167,-1.472 4.588,-2.468 6.166,-2.883c-0.266,-0.092 -0.561,-0.111 -0.852,-0.038c-0.002,0 -0.003,0.001 -0.004,0.001c-1.538,0.394 -4.597,1.334 -6.31,2.92c-0.489,0.451 -1.399,1.461 -1.659,1.98Z"
                  style="fill: #e7dfb6"
                />
              </svg>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import axios from "../plugins/axios";
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    HeaderNav,
  },
  setup() {
    const allSurah = reactive({
      quran: {},
    });

    onMounted(async () => {
      const { data } = await axios.get(
        "https://quran-api-id.vercel.app/surahs"
      );
      allSurah.quran = data;
    });
    return {
      allSurah,
    };
  },
};
</script>
<style></style>
